import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";

const PreviewCompatibleImage = ({
  cover = false,
  children,
  imageInfo,
  remainingStyle,
  ...rest
}) => {
  const { alt = "", childImageSharp, image, publicURL, url = "" } = imageInfo;
  //console.log(imageInfo);
  if (!!image && !!image.childImageSharp) {
    return (
      <BackgroundImage
        style={
          cover ? { backgroundSize: `cover` } : { backgroundSize: `contain` }
        }
        fluid={image.childImageSharp.fluid}
        alt={alt}
        {...rest}
      >
        {children}
      </BackgroundImage>
    );
  }

  if (!!childImageSharp) {
    return (
      <BackgroundImage
        style={
          cover ? { backgroundSize: `cover` } : { backgroundSize: `contain` }
        }
        fluid={childImageSharp.fluid}
        alt={alt}
        {...rest}
      >
        {children}
      </BackgroundImage>
    );
  }
  if (!!publicURL) {
    return (
      <div
        style={{
          backgroundImage: `url(${publicURL})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
          backgroundPosition: `center center`,
          backgroundSize: `${cover ? "cover" : "contain"}`,
          ...remainingStyle,
        }}
        alt={alt}
        {...rest}
      >
        {children}
      </div>
    );
  }

  if (!!url && typeof url === "string")
    return (
      <div
        style={{
          backgroundImage: `url(${url})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
          backgroundSize: `${cover ? "cover" : "contain"} `,
          ...remainingStyle,
        }}
        alt={alt}
        {...rest}
      >
        {children}
      </div>
    );
  if (!!image && typeof image === "string")
    return (
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100%",
          backgroundSize: `${cover ? "cover" : "contain"} `,
          ...remainingStyle,
        }}
        alt={alt}
        {...rest}
      >
        {children}
      </div>
    );

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
