export default {
  breakpoints: {
    mediaSmall: "800px",
  },
  common: {
    palette: {
      red: "#EE2624",
      gray: "#D8D8D8",
      darkGray: "#4A4A4A",
      brown: "#D5BA8C",
      brownGray: "#757575",
      white: "#FFFFFF",
      brownGradientStart: "rgba(195, 148, 78, 0.5)",
      brownGradientEnd: "rgba(144, 91, 38, 0.5)",
      shieldSocietyGradient: {
        background:
          "radial-gradient(120.98% 79.54% at 50.79% 34.15%, rgba(213, 186, 140, 0.0001) 0%, #D5BA8C 37.22%, #BC955C 62.06%, #300505 100%)",
        mixBlendMode: "multiply",
        opacity: 0.7,
      },
    },
    typography: {
      inputLabel: {
        fontFamily: '"Open Sans", sans-serif',
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "30px",
      },
      mediaSmall: {
        heroMedium: {
          fontFamily: "'EB Garamond', serif",
          fontWeight: 800,
          fontSize: "22px",
          color: "#FFFFFF",
          letterSpacing: "1.8888333px",
          lineHeight: "26px",
        },
      },
      heroGiant: {
        fontFamily: "'EB Garamond', serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "78px",
        lineHeight: "72px",
        textAlign: "center",
        letterSpacing: "-1.3px",
      },
      heroLarge: {
        fontFamily: "'EB Garamond', serif",
        fontWeight: 800,
        fontSize: "46px",
        color: "#EE2624",
        letterSpacing: "2.19px",
        textAlign: "center",
        lineHeight: "30px",
      },
      heroMedium: {
        fontFamily: "'EB Garamond', serif",
        fontWeight: 800,
        fontSize: "40px",
        color: "#FFFFFF;",
        letterSpacing: "2px",
        lineHeight: "46px",
      },
      h1: {
        fontFamily: "'EB Garamond', serif",
        fontWeight: 800,
        fontSize: "30px",
        color: "#757575",
        letterSpacing: 0,
        lineHeight: "36px",
      },
      h2: {
        fontFamily: "'EB Garamond', serif",
        fontWeight: 700,
        fontSize: "24px",
        color: "#757575",
        letterSpacing: 0,
        lineHeight: "28px",
      },
      h2LineHeight: {
        fontFamily: "'EB Garamond', serif",
        fontWeight: 700,
        fontSize: "24px",
        color: "#757575",
        letterSpacing: 0,
        lineHeight: "37px",
      },
      h3: {
        fontFamily: "'EB Garamond', serif",
        fontSize: "20px",
        color: "#757575",
        letterSpacing: "0",
        lineHeight: "28px",
      },
      bodyNormal: {
        fontFamily: `'Noto Serif JP', serif`,
        fontSize: "18px",
        color: "#4A4A4A",
        lineHeight: "26px",
        fontWeight: 400,
      },
      nav: {
        fontFamily: "'EB Garamond', serif",
        fontWeight: 400,
        fontSize: "24px",
        color: "#757575",
        letterSpacing: 0,
        lineHeight: "31px",
      },
      iconHeading: {
        fontFamily: "'EB Garamond', serif",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "30px",
        lineHeight: "30px",
        color: "#757575",
        textAlign: "center",
      },
      article: {
        fontFamily: `'Noto Serif JP', serif`,
        fontSize: "18px",
        color: "#4A4A4A",
        lineHeight: "26px",
        fontWeight: 400,
      },
      openSansNormal: {
        fontFamily: "'Open Sans', sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "0.15px",
      },
    },
  },
};
